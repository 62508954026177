import React, { FC, PropsWithChildren } from 'react';

import { Compose } from './Compose';
import { NotificationsProvider } from './notifications';

const providers = [NotificationsProvider];

export const ProtectedRoutesProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  return <Compose components={providers}>{children}</Compose>;
};
