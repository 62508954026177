/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageUserDetailsDto } from '../models';
// @ts-ignore
import { UserCreateRequestDto } from '../models';
// @ts-ignore
import { UserCreateResponseDto } from '../models';
// @ts-ignore
import { UserDetailsDto } from '../models';
// @ts-ignore
import { UserUpdateDto } from '../models';
// @ts-ignore
import { UserUpdatePasswordResponseDto } from '../models';
/**
 * CustomerUserControllerApi - axios parameter creator
 * @export
 */
export const CustomerUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserCreateRequestDto} userCreateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerUser: async (userCreateRequestDto: UserCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateRequestDto' is not null or undefined
            assertParamExists('createCustomerUser', 'userCreateRequestDto', userCreateRequestDto)
            const localVarPath = `/customer-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteCustomerUser', 'userId', userId)
            const localVarPath = `/customer-user/{userId}/delete`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>} [userRole] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>} [status] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUserDetails: async (userRole?: Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>, status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>, search?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userRole) {
                localVarQueryParameter['userRole'] = userRole;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUserDetailsById: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCustomerUserDetailsById', 'userId', userId)
            const localVarPath = `/customer-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreCustomerUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('restoreCustomerUser', 'userId', userId)
            const localVarPath = `/customer-user/{userId}/restore`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerUserDetails: async (userId: number, userUpdateDto: UserUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCustomerUserDetails', 'userId', userId)
            // verify required parameter 'userUpdateDto' is not null or undefined
            assertParamExists('updateCustomerUserDetails', 'userUpdateDto', userUpdateDto)
            const localVarPath = `/customer-user/{userId}/update`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword2: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePassword2', 'userId', userId)
            const localVarPath = `/customer-user/{userId}/password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerUserControllerApi - functional programming interface
 * @export
 */
export const CustomerUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserCreateRequestDto} userCreateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerUser(userCreateRequestDto: UserCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerUser(userCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>} [userRole] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>} [status] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerUserDetails(userRole?: Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>, status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerUserDetails(userRole, status, search, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerUserDetailsById(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerUserDetailsById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreCustomerUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreCustomerUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerUserDetails(userId: number, userUpdateDto: UserUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerUserDetails(userId, userUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword2(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUpdatePasswordResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword2(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerUserControllerApi - factory interface
 * @export
 */
export const CustomerUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerUserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomerUserControllerApiCreateCustomerUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerUser(requestParameters: CustomerUserControllerApiCreateCustomerUserRequest, options?: AxiosRequestConfig): AxiosPromise<UserCreateResponseDto> {
            return localVarFp.createCustomerUser(requestParameters.userCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerUserControllerApiDeleteCustomerUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerUser(requestParameters: CustomerUserControllerApiDeleteCustomerUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCustomerUser(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerUserControllerApiGetCustomerUserDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUserDetails(requestParameters: CustomerUserControllerApiGetCustomerUserDetailsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageUserDetailsDto> {
            return localVarFp.getCustomerUserDetails(requestParameters.userRole, requestParameters.status, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerUserControllerApiGetCustomerUserDetailsByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUserDetailsById(requestParameters: CustomerUserControllerApiGetCustomerUserDetailsByIdRequest, options?: AxiosRequestConfig): AxiosPromise<UserDetailsDto> {
            return localVarFp.getCustomerUserDetailsById(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerUserControllerApiRestoreCustomerUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreCustomerUser(requestParameters: CustomerUserControllerApiRestoreCustomerUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.restoreCustomerUser(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerUserControllerApiUpdateCustomerUserDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerUserDetails(requestParameters: CustomerUserControllerApiUpdateCustomerUserDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateCustomerUserDetails(requestParameters.userId, requestParameters.userUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerUserControllerApiUpdatePassword2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword2(requestParameters: CustomerUserControllerApiUpdatePassword2Request, options?: AxiosRequestConfig): AxiosPromise<UserUpdatePasswordResponseDto> {
            return localVarFp.updatePassword2(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCustomerUser operation in CustomerUserControllerApi.
 * @export
 * @interface CustomerUserControllerApiCreateCustomerUserRequest
 */
export interface CustomerUserControllerApiCreateCustomerUserRequest {
    /**
     * 
     * @type {UserCreateRequestDto}
     * @memberof CustomerUserControllerApiCreateCustomerUser
     */
    readonly userCreateRequestDto: UserCreateRequestDto
}

/**
 * Request parameters for deleteCustomerUser operation in CustomerUserControllerApi.
 * @export
 * @interface CustomerUserControllerApiDeleteCustomerUserRequest
 */
export interface CustomerUserControllerApiDeleteCustomerUserRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerUserControllerApiDeleteCustomerUser
     */
    readonly userId: number
}

/**
 * Request parameters for getCustomerUserDetails operation in CustomerUserControllerApi.
 * @export
 * @interface CustomerUserControllerApiGetCustomerUserDetailsRequest
 */
export interface CustomerUserControllerApiGetCustomerUserDetailsRequest {
    /**
     * 
     * @type {Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>}
     * @memberof CustomerUserControllerApiGetCustomerUserDetails
     */
    readonly userRole?: Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>

    /**
     * 
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>}
     * @memberof CustomerUserControllerApiGetCustomerUserDetails
     */
    readonly status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>

    /**
     * 
     * @type {string}
     * @memberof CustomerUserControllerApiGetCustomerUserDetails
     */
    readonly search?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CustomerUserControllerApiGetCustomerUserDetails
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CustomerUserControllerApiGetCustomerUserDetails
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CustomerUserControllerApiGetCustomerUserDetails
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getCustomerUserDetailsById operation in CustomerUserControllerApi.
 * @export
 * @interface CustomerUserControllerApiGetCustomerUserDetailsByIdRequest
 */
export interface CustomerUserControllerApiGetCustomerUserDetailsByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerUserControllerApiGetCustomerUserDetailsById
     */
    readonly userId: number
}

/**
 * Request parameters for restoreCustomerUser operation in CustomerUserControllerApi.
 * @export
 * @interface CustomerUserControllerApiRestoreCustomerUserRequest
 */
export interface CustomerUserControllerApiRestoreCustomerUserRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerUserControllerApiRestoreCustomerUser
     */
    readonly userId: number
}

/**
 * Request parameters for updateCustomerUserDetails operation in CustomerUserControllerApi.
 * @export
 * @interface CustomerUserControllerApiUpdateCustomerUserDetailsRequest
 */
export interface CustomerUserControllerApiUpdateCustomerUserDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerUserControllerApiUpdateCustomerUserDetails
     */
    readonly userId: number

    /**
     * 
     * @type {UserUpdateDto}
     * @memberof CustomerUserControllerApiUpdateCustomerUserDetails
     */
    readonly userUpdateDto: UserUpdateDto
}

/**
 * Request parameters for updatePassword2 operation in CustomerUserControllerApi.
 * @export
 * @interface CustomerUserControllerApiUpdatePassword2Request
 */
export interface CustomerUserControllerApiUpdatePassword2Request {
    /**
     * 
     * @type {number}
     * @memberof CustomerUserControllerApiUpdatePassword2
     */
    readonly userId: number
}

/**
 * CustomerUserControllerApi - object-oriented interface
 * @export
 * @class CustomerUserControllerApi
 * @extends {BaseAPI}
 */
export class CustomerUserControllerApi extends BaseAPI {
    /**
     * 
     * @param {CustomerUserControllerApiCreateCustomerUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public createCustomerUser(requestParameters: CustomerUserControllerApiCreateCustomerUserRequest, options?: AxiosRequestConfig) {
        return CustomerUserControllerApiFp(this.configuration).createCustomerUser(requestParameters.userCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerUserControllerApiDeleteCustomerUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public deleteCustomerUser(requestParameters: CustomerUserControllerApiDeleteCustomerUserRequest, options?: AxiosRequestConfig) {
        return CustomerUserControllerApiFp(this.configuration).deleteCustomerUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerUserControllerApiGetCustomerUserDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public getCustomerUserDetails(requestParameters: CustomerUserControllerApiGetCustomerUserDetailsRequest = {}, options?: AxiosRequestConfig) {
        return CustomerUserControllerApiFp(this.configuration).getCustomerUserDetails(requestParameters.userRole, requestParameters.status, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerUserControllerApiGetCustomerUserDetailsByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public getCustomerUserDetailsById(requestParameters: CustomerUserControllerApiGetCustomerUserDetailsByIdRequest, options?: AxiosRequestConfig) {
        return CustomerUserControllerApiFp(this.configuration).getCustomerUserDetailsById(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerUserControllerApiRestoreCustomerUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public restoreCustomerUser(requestParameters: CustomerUserControllerApiRestoreCustomerUserRequest, options?: AxiosRequestConfig) {
        return CustomerUserControllerApiFp(this.configuration).restoreCustomerUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerUserControllerApiUpdateCustomerUserDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public updateCustomerUserDetails(requestParameters: CustomerUserControllerApiUpdateCustomerUserDetailsRequest, options?: AxiosRequestConfig) {
        return CustomerUserControllerApiFp(this.configuration).updateCustomerUserDetails(requestParameters.userId, requestParameters.userUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerUserControllerApiUpdatePassword2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public updatePassword2(requestParameters: CustomerUserControllerApiUpdatePassword2Request, options?: AxiosRequestConfig) {
        return CustomerUserControllerApiFp(this.configuration).updatePassword2(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }
}
