import { styled } from '@mui/material/styles';

export const CopyIconWrapper = styled('div')(() => ({
  cursor: 'pointer',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
