import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { EditUserPopup } from './components';
import { ProfileContent } from './ProfileContent';
import { ProfilePageWrapper } from './styles';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { BreadcrumbItem } from 'components/common/Breadcrumbs/types';
import { MenuItemType } from 'components/common/DropdownMenu/DropdownMenu';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { UserCredentials } from 'components/shared';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import useUser from 'contexts/user';
import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const CustomerProfile: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useParams();
  const { customerUserControllerApi } = useApi();
  const { user: currentUser } = useUser();

  const { openPopup, closePopup } = useResponsePopup();

  const [editPopupOpen, setEditPopupOpen] = useState(false);

  const getCustomerUserDetails = useCallback(async () => {
    if (typeof userId === 'undefined') return;

    try {
      return (
        await customerUserControllerApi.getCustomerUserDetailsById({
          userId: Number(userId),
        })
      ).data;
    } catch {}
  }, [customerUserControllerApi, userId]);

  const {
    resource: user,
    fetch,
    isLoading,
  } = useAsyncResource({
    fetchResource: getCustomerUserDetails,
  });

  const breadcrumbItems = useMemo((): BreadcrumbItem[] => {
    return [
      {
        label: t('pages.users.title'),
        path: routePaths.users.root,
      },
      ...(!isLoading && !!user?.userId
        ? [
            {
              label: user.username as string,
              path: routePaths.users.profile(user?.userId),
            },
          ]
        : []),
    ];
  }, [t, isLoading, user]);

  const resetPassword = useCallback(async () => {
    if (typeof user?.userId === 'undefined') return;

    try {
      const { data } = await customerUserControllerApi.updatePassword2({
        userId: user.userId,
      });

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.resetPasswordPopup.successTitle'),
        subtitle: t('pages.users.resetPasswordPopup.successSubtitle'),
        children: (
          <UserCredentials username={user.username} password={data.password} />
        ),
        primaryButton: {
          text: t('continueSession'),
          onClick: closePopup,
        },
      });
    } catch {}
  }, [user, customerUserControllerApi, openPopup, t, closePopup]);

  const deleteUser = useCallback(async () => {
    if (typeof user?.userId === 'undefined') return;

    try {
      await customerUserControllerApi.deleteCustomerUser({
        userId: user.userId,
      });
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.deleteUserPopup.successTitle'),
        primaryButton: {
          onClick: () => {
            closePopup();
            navigate(routePaths.users.root);
          },
          text: t('buttons.continueSession'),
        },
      });
    } catch {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('pages.users.deleteUserPopup.failTitle'),
        subtitle: t('pages.users.deleteUserPopup.failSubtitle'),
        secondaryButton: {
          onClick: closePopup,
          text: t('back'),
        },
      });
    }
  }, [
    user?.userId,
    customerUserControllerApi,
    openPopup,
    t,
    closePopup,
    navigate,
  ]);

  const restoreAccount = useCallback(async () => {
    if (typeof user?.userId === 'undefined') return;

    try {
      await customerUserControllerApi.restoreCustomerUser({
        userId: user.userId,
      });
      await fetch();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.restoreUserPopup.successTitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: closePopup,
        },
      });
    } catch {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('pages.users.restoreUserPopup.failTitle'),
        subtitle: t('pages.users.restoreUserPopup.failSubtitle'),
        secondaryButton: {
          onClick: closePopup,
          text: t('back'),
        },
      });
    }
  }, [
    user?.userId,
    customerUserControllerApi,
    fetch,
    openPopup,
    t,
    closePopup,
  ]);

  const onEditPopupClose = useCallback(() => {
    setEditPopupOpen(false);
    fetch();
  }, [fetch]);

  const onResetPasswordClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.resetPasswordPopup.title'),
      subtitle: t('pages.users.resetPasswordPopup.subtitle'),
      primaryButton: {
        onClick: resetPassword,
        text: t('pages.users.resetPasswordPopup.primaryButtonText'),
      },
      secondaryButton: {
        onClick: closePopup,
        text: t('buttons.cancel'),
      },
    });
  }, [openPopup, closePopup, t, resetPassword]);

  const onDeleteAccountClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.deleteUserPopup.customerTitle'),
      subtitle: t('pages.users.deleteUserPopup.customerSubtitle'),
      primaryButton: {
        onClick: deleteUser,
        text: t('pages.users.deleteUserPopup.primaryButtonText'),
      },
      secondaryButton: {
        onClick: closePopup,
        text: t('buttons.cancel'),
      },
    });
  }, [deleteUser, openPopup, closePopup, t]);

  const onRestoreAccountClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.restoreUserPopup.title'),
      subtitle: t('pages.users.restoreUserPopup.customerSubtitle'),
      primaryButton: {
        text: t('pages.users.restoreUserPopup.submitButtonText'),
        onClick: restoreAccount,
      },
      secondaryButton: {
        text: t('buttons.cancel'),
        onClick: closePopup,
      },
    });
  }, [t, openPopup, closePopup, restoreAccount]);

  const actionsMenuItems = useMemo((): MenuItemType[] => {
    if (
      typeof user?.userId === 'undefined' ||
      user.userId === currentUser.userId
    ) {
      return [];
    }

    return [
      {
        label: t('pages.users.resetPassword'),
        onClick: onResetPasswordClick,
      },
      ...(user?.status === UserDetailsDtoStatusEnum.ACTIVE
        ? [
            {
              label: t('pages.users.deleteAccount'),
              onClick: onDeleteAccountClick,
              color: 'error.main',
            },
          ]
        : [
            {
              label: t('pages.users.restoreAccount'),
              onClick: onRestoreAccountClick,
            },
          ]),
    ];
  }, [
    t,
    user,
    currentUser,
    onResetPasswordClick,
    onDeleteAccountClick,
    onRestoreAccountClick,
  ]);

  return (
    <ProfilePageWrapper>
      <Breadcrumbs items={breadcrumbItems} />
      <ProfileContent
        username={user?.username}
        status={user?.status}
        email={user?.email}
        userRole={user?.userRole}
        onEditUserClick={() => setEditPopupOpen(true)}
        actionsMenuItems={actionsMenuItems}
      />
      {editPopupOpen && (
        <EditUserPopup
          userId={userId}
          username={user?.username || ''}
          email={user?.email || ''}
          userRole={user?.userRole || ''}
          isOpen
          onClose={onEditPopupClose}
        />
      )}
    </ProfilePageWrapper>
  );
};
