import { SxProps, Theme, useMediaQuery } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { generateColumns } from './columns';
import { clickDisabledFields } from './constants';
import { AppliedFilters } from 'components/common/AppliedFilters';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import {
  CommonTable,
  TablePagination,
} from 'components/common/Table/CommonTable';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import { FilterItem, FilterValues } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

interface Props {
  fieldsToSend: string[];
  checkedFilterValues: Record<string, FilterItem>;
  filterValues: FilterValues;
  sx?: SxProps;
}

export const UsersTable: FC<Props> = ({
  fieldsToSend,
  checkedFilterValues,
  filterValues,
}) => {
  const navigate = useNavigate();
  const { customerUserControllerApi } = useApi();
  const isDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.only('desktop'),
  );
  const { t } = useTranslation();

  const { openPopup, closePopup } = useResponsePopup();

  const {
    sortModel,
    onSortModelChange,
    searchParams,
    paginationModel,
    onPaginationModelChange,
    onFilterModelChange,
  } = useDataGrid();

  const getUsers = useCallback(async () => {
    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);

    try {
      return (
        await customerUserControllerApi.getCustomerUserDetails({
          ...objFromQuery,
        })
      ).data;
    } catch {}
  }, [fieldsToSend, searchParams, customerUserControllerApi]);

  const {
    resource: users,
    fetch: fetchUsers,
    isLoading,
  } = useAsyncResourceWithPulling({
    fetchResource: getUsers,
    pullingInterval: 30,
  });

  const openRestoreSuccessPopup = useCallback(() => {
    fetchUsers();
    openPopup({
      variant: NotificationPopupVariants.Success,
      title: t('pages.users.restoreUserPopup.successTitle'),
      primaryButton: {
        text: t('buttons.continueSession'),
        onClick: closePopup,
      },
    });
  }, [openPopup, closePopup, fetchUsers, t]);

  const restoreUser = useCallback(
    async (userId: number) => {
      try {
        await customerUserControllerApi.restoreCustomerUser({ userId });
        openRestoreSuccessPopup();
      } catch {
        openPopup({
          variant: NotificationPopupVariants.Error,
          title: t('pages.users.restoreUserPopup.failTitle'),
          subtitle: t('pages.users.restoreUserPopup.failSubtitle'),
          secondaryButton: {
            onClick: closePopup,
            text: t('back'),
          },
        });
      }
    },
    [
      closePopup,
      customerUserControllerApi,
      openPopup,
      openRestoreSuccessPopup,
      t,
    ],
  );

  const onRestoreButtonClick = useCallback(
    (userId?: number) => {
      if (typeof userId === 'undefined') return;

      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('pages.users.restoreUserPopup.title'),
        subtitle: t('pages.users.restoreUserPopup.customerSubtitle'),
        primaryButton: {
          text: t('pages.users.restoreUserPopup.submitButtonText'),
          onClick: () => restoreUser(userId),
        },
        secondaryButton: {
          text: t('buttons.cancel'),
          onClick: closePopup,
        },
      });
    },
    [openPopup, closePopup, restoreUser, t],
  );

  const columns = useMemo(
    () => generateColumns({ t, onRestoreButtonClick }),
    [t, onRestoreButtonClick],
  );

  const rows = useMemo(
    () =>
      users?.content?.map((user) => ({
        id:
          typeof user.userId !== 'undefined'
            ? user.userId
            : user.createDatetime,
        ...user,
      })) || [],
    [users?.content],
  );

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  const onCellClick = useCallback(
    ({ row, field }: GridCellParams) => {
      if (clickDisabledFields.includes(field)) return;

      if (typeof row.userId !== 'undefined') {
        navigate(routePaths.users.profile(row.userId));
      }
    },
    [navigate],
  );

  return (
    <>
      <AppliedFilters
        checkedFilterValues={checkedFilterValues}
        filterValues={filterValues}
        onFilterModelChange={onFilterModelChange}
        sx={{ mt: 2 }}
      />
      <CommonTable
        columns={columns}
        rows={rows}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        rowCount={users?.size}
        onCellClick={onCellClick}
        loading={isLoading}
        sx={{
          mt: 2.5,
          maxWidth: '100%',
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'grey.50',
            cursor: 'pointer',
          },
        }}
      />
      <TablePagination
        count={users?.totalElements}
        page={paginationModel.page}
        rowsPerPage={paginationModel.pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        onPaginationModelChange={onPaginationModelChange}
        sx={{
          ...(!isDesktop && {
            '& .MuiTablePagination-input': { width: 66 },
          }),
        }}
      />
    </>
  );
};
