import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { defaultValues } from './constants';
import { FormValues } from './types';
import { useOrganizationIds } from './useOrganizationIds';
import { validationSchema } from './validationSchema';
import { FormWrapper } from '../../styles';
import CommonButton from 'components/common/CommonButton';
import { PasswordField } from 'components/common/form/PasswordField';
import TextField from 'components/common/form/TextField';
import routePaths from 'constants/routePaths';
import useAuth from 'contexts/auth';

interface Props {
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserForm: FC<Props> = ({ setError }) => {
  const { onUserLogin } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { lastFilledValue, addValue: addOrganizationId } = useOrganizationIds();
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
  });

  const { setValue, handleSubmit: handleSubmitForm, watch } = form;
  const values = watch();

  useEffect(() => {
    if (lastFilledValue) setValue('organizationId', lastFilledValue);
  }, [lastFilledValue, setValue]);

  const handleSubmit = handleSubmitForm(async (data: FormValues) => {
    setError(false);
    try {
      await onUserLogin(data.organizationId, data.username, data.password);

      addOrganizationId(data.organizationId);

      if (state?.from) {
        navigate(state.from);
      } else {
        navigate(routePaths.parks.root);
      }
    } catch {
      setError(true);
    }
  });

  return (
    <FormProvider {...form}>
      <FormWrapper onSubmit={handleSubmit}>
        <TextField
          label={t('pages.logIn.organizationId')}
          fullWidth
          name="organizationId"
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 4 }}
        />
        <TextField
          label={t('username')}
          fullWidth
          name="username"
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 4 }}
        />
        <PasswordField
          label={t('password')}
          fullWidth
          name="password"
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 6 }}
        />
        <CommonButton
          type="submit"
          variant="contained"
          sx={{ width: '200px' }}
          disabled={
            !values.organizationId || !values.username || !values.password
          }
        >
          {t('buttons.submit')}
        </CommonButton>
      </FormWrapper>
    </FormProvider>
  );
};
