import { Box, SxProps, Typography } from '@mui/material';
import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChevronDownIcon,
  UserMenuHead,
  UserMenuList,
  UserMenuListItem,
  UserMenuListItemIcon,
  UserMenuListItemWrapper,
  UserMenuPopover,
  UserMenuWrapper,
} from './styles';
import { Link } from '../Link';
import { Logout, Settings } from 'components/icons';
import routePaths from 'constants/routePaths';
import useAuth from 'contexts/auth';
import useUser from 'contexts/user';

interface Props {
  testId?: string;
  sx?: SxProps;
}

export const UserMenu: FC<Props> = ({ testId, sx }) => {
  const { t } = useTranslation();
  const { onLogout } = useAuth();
  const { user, translatedRole } = useUser();

  const ref = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const onWrapperClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <Box sx={sx}>
      <UserMenuWrapper
        open={open}
        ref={ref}
        onClick={onWrapperClick}
        data-testid={testId}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            variant="bodySStrong"
            color="black.900"
            maxWidth="111px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {user.username}
          </Typography>
          <Typography
            variant="bodyXS"
            color="grey.800"
            textTransform="capitalize"
          >
            {translatedRole}
          </Typography>
        </Box>
        <ChevronDownIcon />
      </UserMenuWrapper>
      <UserMenuPopover
        open={open}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -9.5,
          horizontal: 'right',
        }}
        onClose={() => setOpen(false)}
      >
        <UserMenuHead>
          <Typography variant="h5" color="black.900">
            {user.username}
          </Typography>
          <Typography
            variant="bodyM"
            color="grey.400"
            textTransform="capitalize"
          >
            {translatedRole}
          </Typography>
        </UserMenuHead>
        <UserMenuList>
          <UserMenuListItem>
            <Link to={routePaths.updateProfile} component="a">
              <UserMenuListItemWrapper onClick={() => setOpen(false)}>
                <UserMenuListItemIcon>
                  <Settings />
                </UserMenuListItemIcon>
                <Typography variant="bodyMStrong">
                  {t('components.header.updateProfile')}
                </Typography>
              </UserMenuListItemWrapper>
            </Link>
          </UserMenuListItem>
          <UserMenuListItem>
            <UserMenuListItemWrapper onClick={onLogout}>
              <UserMenuListItemIcon>
                <Logout />
              </UserMenuListItemIcon>
              <Typography variant="bodyMStrong">
                {t('components.header.logOut')}
              </Typography>
            </UserMenuListItemWrapper>
          </UserMenuListItem>
        </UserMenuList>
      </UserMenuPopover>
    </Box>
  );
};
