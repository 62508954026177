import { styled } from '@mui/material';

export const UserMenuList = styled('ul')(() => ({
  margin: 0,
  padding: 0,
}));

export const UserMenuListItem = styled('li')(() => ({
  listStyleType: 'none',
  '& a': {
    textDecoration: 'none',
  },
}));

export const UserMenuListItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5, 2),
  cursor: 'pointer',

  '& .MuiTypography-root': {
    color: theme.palette.black[900],
  },

  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
}));

export const UserMenuListItemIcon = styled('div')(({ theme }) => ({
  '& svg': {
    height: '24px',
    width: '24px',
  },

  '& path': {
    stroke: theme.palette.grey[300],
  },
}));
