import { AxiosError } from 'axios';

export const isUnauthorizedError = (error: AxiosError) =>
  error.response?.status === 401;

export const isForbiddenError = (error: AxiosError) =>
  error.response?.status === 403;

export const isConnectionError = (error: AxiosError) =>
  error.response?.status === 503;
