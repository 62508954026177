import { styled } from '@mui/material/styles';

export const MobileMenuBody = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2.5, 8.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',

  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(0, 2.5, 5),
  },
}));
