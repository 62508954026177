import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

export const validationSchema = (t: TranslationFunction) =>
  yup.object({
    email: yup
      .string()
      .email(t('form.errors.invalidEmail'))
      .required(t('form.errors.fieldRequired')),
  });
