import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import useUser from 'contexts/user';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';

export const useResendEmailVerification = (
  handleClose?: () => void,
  allowSuccessPopupClose = true,
): [boolean, () => Promise<void>, () => Promise<void>] => {
  const { t } = useTranslation();
  const { isSuperAdmin } = useUser();
  const { emailVerificationControllerApi } = useApi();
  const { openPopup, closePopup } = useResponsePopup();

  const [
    isResendVerificationLinkAvailable,
    setIsResendVerificationLinkAvailable,
  ] = useState(false);

  const handleCloseSuccess = useCallback(() => {
    closePopup();
    handleClose?.();
  }, [closePopup, handleClose]);

  const checkResendVerificationButtonAvailability = useCallback(async () => {
    if (isSuperAdmin) return;

    try {
      const linkData = (
        await emailVerificationControllerApi.getVerificationLinkDetails()
      ).data;

      if (!linkData.linkGenerationDatetime) return;

      const daysPastLastLinkGenerated = dayjs().diff(
        dayjs(linkData.linkGenerationDatetime),
        'day',
      );

      if (daysPastLastLinkGenerated < 1) {
        setIsResendVerificationLinkAvailable(false);
      } else {
        setIsResendVerificationLinkAvailable(true);
      }
    } catch {}
  }, [emailVerificationControllerApi, isSuperAdmin]);

  useAsyncResourceWithPulling({
    fetchResource: checkResendVerificationButtonAvailability,
    pullingInterval: 30,
  });

  const handleResendVerificationLink = useCallback(async () => {
    if (!isResendVerificationLinkAvailable) return;

    try {
      await emailVerificationControllerApi.sendEmailVerificationLink();

      setIsResendVerificationLinkAvailable(false);

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.emailVerification.resentEmailVerificationTitle'),
        subtitle: t('pages.emailVerification.resentEmailVerificationSubtitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: handleCloseSuccess,
        },
        allowPopupClose: allowSuccessPopupClose,
      });
    } catch (e) {}
  }, [
    allowSuccessPopupClose,
    emailVerificationControllerApi,
    handleCloseSuccess,
    isResendVerificationLinkAvailable,
    openPopup,
    t,
  ]);

  return [
    isResendVerificationLinkAvailable,
    handleResendVerificationLink,
    checkResendVerificationButtonAvailability,
  ];
};
