import { useCallback, useEffect, useMemo, useState } from 'react';

const organizationIdsLocalStorageKey = 'organizationIds';

export const useOrganizationIds = () => {
  const [organizationIds, setOrganizationIds] = useState<string[]>([]);

  useEffect(() => {
    const localStorageValue = window.localStorage.getItem(
      organizationIdsLocalStorageKey,
    );

    setOrganizationIds(
      localStorageValue ? (JSON.parse(localStorageValue) as string[]) : [],
    );
  }, []);

  const lastFilledValue = useMemo(() => {
    return organizationIds?.[0] || '';
  }, [organizationIds]);

  const addValue = useCallback(
    (value: string) => {
      const newList = [...organizationIds];

      const existingValueIndex = newList.indexOf(value);

      if (existingValueIndex === -1) {
        newList.unshift(value);
      } else {
        newList.splice(existingValueIndex, 1);
        newList.unshift(value);
      }

      window.localStorage.setItem(
        organizationIdsLocalStorageKey,
        JSON.stringify(newList),
      );
      setOrganizationIds(newList);
    },
    [organizationIds],
  );

  return {
    lastFilledValue,
    addValue,
  };
};
