import { Grid, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ActionsMenuButton,
  InfoRowWrapper,
  RoundChip,
  UsernameWrapper,
} from './styles';
import { ContainerStyled } from '../../styles';
import { EditAssetDetailsPopup } from '../EditAssetDetailsPopup';
import { IdBlock } from '../IdBlock';
import { MobileMenuActions } from '../MobileMenuActions';
import {
  WindTurbineControl,
  WindTurbineControlStatus,
} from '../WindTurbineControl';
import { ControlStatuses } from '../WindTurbineControl/types';
import { WindTurbineStatus } from '../WindTurbineStatus';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import CommonButton from 'components/common/CommonButton';
import { ThreeDots } from 'components/icons';
import { RolePermissionWrapper } from 'components/shared/RolePermissionWrapper';
import routePaths from 'constants/routePaths';
import useUser from 'contexts/user';
import useWindTurbine from 'contexts/WindTurbine';
import {
  UserDetailsDtoUserRoleEnum,
  WindTurbineDto,
  WindTurbineDtoAccelerationStatusEnum,
  WindTurbineDtoOperationalStatusEnum,
} from 'openapi-api/admin-service';
import { mapRatedPower } from 'utils/functions/mapRatedPower';
import { usePopup } from 'utils/hooks/usePopup';

interface HeadBlockProps extends WindTurbineDto {}

export const HeadBlock: FC<HeadBlockProps> = () => {
  const { t } = useTranslation();
  const { publicId } = useParams();
  const { isViewer } = useUser();
  const [
    isEditAssetDetailsPopupOpen,
    openEditAssetDetailsPopup,
    closeEditAssetDetailsPopup,
  ] = usePopup();
  const [isActionsOpen, openActions, closeActions] = usePopup();

  const { windTurbine, handleTurbineActivityChange } = useWindTurbine();

  const parentPath = useMemo(() => {
    return routePaths.parks.specific(windTurbine?.location?.id!);
  }, [windTurbine?.location?.id]);

  const onEditAssetDetailsClick = useCallback(() => {
    openEditAssetDetailsPopup();
  }, [openEditAssetDetailsPopup]);

  const assetOperationalStatus = useMemo(
    () =>
      windTurbine?.operationalStatus ===
      WindTurbineDtoOperationalStatusEnum.ACTIVATED
        ? ControlStatuses.ACTIVE
        : ControlStatuses.INACTIVE,
    [windTurbine?.operationalStatus],
  );

  const assetOperationalStatusText = useMemo(() => {
    const text =
      assetOperationalStatus === ControlStatuses.ACTIVE
        ? t('active')
        : t('inactive');

    return text.toUpperCase();
  }, [assetOperationalStatus, t]);

  const accelerationStatus = useMemo(
    () =>
      windTurbine?.accelerationStatus ===
      WindTurbineDtoAccelerationStatusEnum.ACTIVATED
        ? ControlStatuses.ACTIVE
        : ControlStatuses.INACTIVE,
    [windTurbine?.accelerationStatus],
  );

  const accelerationStatusText = useMemo(() => {
    if (accelerationStatus === ControlStatuses.ACTIVE) {
      return t('pages.turbine.on').toUpperCase();
    }

    return t('pages.turbine.off').toUpperCase();
  }, [accelerationStatus, t]);

  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid item mobile={12}>
          <Breadcrumbs
            items={[
              {
                label: t('pages.parks.title'),
                path: routePaths.parks.root,
              },
              {
                label: windTurbine?.location?.id
                  ? windTurbine.location.name
                  : t('pages.unassignedWindTurbines.title'),
                path: parentPath.root,
              },
              {
                label: publicId as string,
                path: parentPath.windTurbine(windTurbine?.publicId as string)
                  .root,
              },
            ]}
          />
        </Grid>
        <Grid item mobile={12} desktop={6}>
          <Grid container alignItems="center">
            <Grid item container gap={1.5} alignItems="center">
              <WindTurbineStatus
                status={windTurbine?.status}
                errorType={windTurbine?.errorType}
                height={20}
              />
              {!!windTurbine?.location?.name && (
                <>
                  <RoundChip color="grey" colorKey="200" size={4} />
                  <Typography variant="subheading">
                    {windTurbine.location.name}
                  </Typography>
                </>
              )}
              {!!windTurbine?.ratedPower && (
                <>
                  <RoundChip color="grey" colorKey="200" size={4} />
                  <Typography variant="subheading">
                    {`${mapRatedPower(windTurbine.ratedPower)}kW`}
                  </Typography>
                </>
              )}
            </Grid>
            <Typography variant="h1" sx={{ mt: 1 }}>
              {windTurbine?.name || publicId}
            </Typography>
          </Grid>

          <Grid container sx={{ mt: 2.5 }} alignItems="center">
            <InfoRowWrapper display="flex" gap={2}>
              <IdBlock id={publicId} />
              <RolePermissionWrapper
                disallowedRoles={[
                  UserDetailsDtoUserRoleEnum.USER_PORTAL_VIEWER,
                ]}
              >
                <CommonButton
                  onClick={onEditAssetDetailsClick}
                  sx={{ display: { mobile: 'none', desktop: 'flex' } }}
                  variant="outlined"
                  size="medium"
                >
                  {t('pages.turbine.editAssetDetails')}
                </CommonButton>
              </RolePermissionWrapper>
              {!!windTurbine?.connected && (
                <UsernameWrapper variant="h5" mr={2.5}>
                  {windTurbine.customerName}
                </UsernameWrapper>
              )}
            </InfoRowWrapper>

            <RolePermissionWrapper
              disallowedRoles={[UserDetailsDtoUserRoleEnum.USER_PORTAL_VIEWER]}
            >
              <ActionsMenuButton
                variant="outlined"
                onClick={openActions}
                sx={{ ml: 'auto' }}
              >
                <ThreeDots />
              </ActionsMenuButton>
            </RolePermissionWrapper>
          </Grid>
        </Grid>
        <Grid container item mobile={12} desktop={6} rowSpacing={4}>
          <Grid
            container
            item
            columnSpacing={{ tablet: 2.5, desktop: 2 }}
            rowSpacing={{ mobile: 2.5 }}
          >
            <Grid item mobile={12} tablet={6}>
              {windTurbine?.operationalStatus ? (
                <WindTurbineControl
                  name={t('pages.turbine.turbineActivity')}
                  value={
                    windTurbine.operationalStatus ===
                    WindTurbineDtoOperationalStatusEnum.ACTIVATED
                  }
                  onClick={handleTurbineActivityChange}
                  dataTestId="wind-turbine-activity-switch"
                  disabled={isViewer}
                >
                  <WindTurbineControlStatus
                    status={assetOperationalStatus}
                    text={assetOperationalStatusText}
                    dataTestId="wind-turbine-activity-status"
                  />
                </WindTurbineControl>
              ) : (
                <ContainerStyled />
              )}
            </Grid>
            <Grid item mobile={12} tablet={6}>
              {windTurbine?.accelerationStatus ? (
                <WindTurbineControl
                  name={t('pages.turbine.accelerationMode')}
                  value={
                    windTurbine?.accelerationStatus ===
                    WindTurbineDtoAccelerationStatusEnum.ACTIVATED
                  }
                  disabled={isViewer}
                >
                  <WindTurbineControlStatus
                    status={accelerationStatus}
                    text={accelerationStatusText}
                  />
                </WindTurbineControl>
              ) : (
                <ContainerStyled />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EditAssetDetailsPopup
        isOpen={isEditAssetDetailsPopupOpen}
        onClose={closeEditAssetDetailsPopup}
      />
      <MobileMenuActions
        isOpen={isActionsOpen}
        onClose={closeActions}
        onEditAssetDetailsClick={onEditAssetDetailsClick}
      />
    </>
  );
};
