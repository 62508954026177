import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

export const validationSchema = (t: TranslationFunction) =>
  yup.object({
    username: yup.string(),
    email: yup.string().email(t('form.errors.invalidEmail')),
    userRole: yup.string().required(),
  });
