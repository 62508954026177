import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UserCredentialsWrapper } from './styles';
import { CopyButton } from 'components/common/CopyButton';
import { CopyIcon } from 'components/common/CopyIcon';
import { getUserCredentialsCopyText } from 'utils/functions/getUserCredentialsCopyText';

interface Props {
  username?: string;
  password?: string;
}

export const UserCredentials: FC<Props> = ({ username, password }) => {
  const { t } = useTranslation();

  return (
    <UserCredentialsWrapper>
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Grid display="flex" flexDirection="column" gap={1}>
          <Typography variant="bodyS" color="grey.600">
            {t('username')}
          </Typography>
          <Typography variant="bodyMStrong" color="black.900">
            {username}
          </Typography>
        </Grid>
        <CopyIcon text={username} />
      </Grid>
      <Grid
        display="flex"
        justifyContent="space-between"
        gap={3}
        alignItems="center"
      >
        <Grid display="flex" flexDirection="column" gap={1}>
          <Typography variant="bodyS" color="grey.600">
            {t('password')}
          </Typography>
          <Typography variant="bodyMStrong" color="black.900">
            {password}
          </Typography>
        </Grid>
        <CopyIcon text={password} />
      </Grid>
      <CopyButton
        text={getUserCredentialsCopyText({ name: username, password })}
      />
    </UserCredentialsWrapper>
  );
};
