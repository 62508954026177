import { Select, styled } from '@mui/material';

export const SelectStyled = styled(Select)(() => ({
  textAlign: 'left',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    top: '16px',
    right: '16px',
  },
  '& .MuiGrid-root': {
    flexWrap: 'wrap',
  },
}));
