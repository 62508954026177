import { alpha, styled } from '@mui/material';

export const UserMenuWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  padding: theme.spacing(0.75, 2.5),
  borderRadius: '56px',
  backgroundColor: alpha(theme.palette.white.main, 0.2),
  cursor: 'pointer',

  '& path': {
    stroke: theme.palette.grey[600],
  },

  ...(open && {
    backgroundColor: alpha(theme.palette.white.main, 0.5),
    '& svg': {
      transform: 'rotate(180deg)',
    },
    '& path': {
      stroke: theme.palette.black[900],
    },
  }),
}));
