import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Logout: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H15"
      stroke="#1E2228"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 15L19 12M19 12L16 9M19 12H9"
      stroke="#1E2228"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
