import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

export const validationSchema = (t: TranslationFunction) =>
  yup.object({
    username: yup
      .string()
      .required(t('form.errors.fieldRequired'))
      .matches(
        /^[a-zA-Z0-9][a-zA-Z0-9-_]{8,30}[a-zA-Z0-9]$/,
        t('form.errors.incorrectUsername'),
      ),
    email: yup.string().email(t('form.errors.invalidEmail')),
    userRole: yup.string().required(),
  });
