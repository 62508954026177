import { SxProps, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';

import NoDataContainer from './styles/NoDataContainer';
import CommonButton from '../CommonButton';
import { ConditionalWrapper } from '../ConditionalWrapper';

interface NoDataProps {
  sx?: SxProps<Theme>;
  text?: string;
  buttonText?: string;
  buttonLinkTo?: To;
  onButtonClick?: () => void;
}
export const NoData: FC<NoDataProps> = ({
  sx,
  text,
  buttonText,
  buttonLinkTo,
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <NoDataContainer sx={sx}>
      <Typography variant="bodyMStrong" color="grey.600">
        {text || t`noData`}
      </Typography>
      {buttonText && onButtonClick && (
        <ConditionalWrapper
          condition={!!buttonLinkTo}
          Wrapper={Link}
          wrapperProps={{ to: buttonLinkTo as To, onClick: onButtonClick }}
        >
          <CommonButton variant="outlined" size="small" onClick={onButtonClick}>
            {buttonText}
          </CommonButton>
        </ConditionalWrapper>
      )}
    </NoDataContainer>
  );
};
