/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserDetailsDto
 */
export interface UserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof UserDetailsDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsDto
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsDto
     */
    'emailVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'userRole'?: UserDetailsDtoUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'emailUpdateDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'lastSignInDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'createDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'statusUpdateDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'status'?: UserDetailsDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsDto
     */
    'admin'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDetailsDtoUserRoleEnum {
    ADMIN_PORTAL_SUPER_ADMIN = 'ADMIN_PORTAL_SUPER_ADMIN',
    ADMIN_PORTAL_ADMIN = 'ADMIN_PORTAL_ADMIN',
    ADMIN_PORTAL_VIEWER = 'ADMIN_PORTAL_VIEWER',
    USER_PORTAL_SUPER_ADMIN = 'USER_PORTAL_SUPER_ADMIN',
    USER_PORTAL_ADMIN = 'USER_PORTAL_ADMIN',
    USER_PORTAL_VIEWER = 'USER_PORTAL_VIEWER'
}
/**
    * @export
    * @enum {string}
    */
export enum UserDetailsDtoStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED'
}


