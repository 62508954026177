import { UserCreateRequestDtoUserRoleEnum } from 'openapi-api/admin-service';
import { SelectItem, TranslationFunction } from 'types/general';

export const getUserRoleSelectItems = (
  t: TranslationFunction,
): SelectItem[] => [
  {
    label: t(`userRoles.${UserCreateRequestDtoUserRoleEnum.USER_PORTAL_ADMIN}`),
    value: UserCreateRequestDtoUserRoleEnum.USER_PORTAL_ADMIN,
  },
  {
    label: t(
      `userRoles.${UserCreateRequestDtoUserRoleEnum.USER_PORTAL_VIEWER}`,
    ),
    value: UserCreateRequestDtoUserRoleEnum.USER_PORTAL_VIEWER,
  },
];
