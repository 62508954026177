import {
  UserDetailsDtoUserRoleEnum,
  UserDetailsDtoStatusEnum,
} from 'openapi-api/admin-service';
import { FilterValues, TranslationFunction } from 'types/general';

export const usersFilterValues = (t: TranslationFunction): FilterValues => ({
  userRole: {
    label: t('pages.users.filters.roleFilterTitle'),
    values: [
      {
        label: t(`userRoles.${UserDetailsDtoUserRoleEnum.USER_PORTAL_ADMIN}`),
        value: UserDetailsDtoUserRoleEnum.USER_PORTAL_ADMIN,
      },
      {
        label: t(`userRoles.${UserDetailsDtoUserRoleEnum.USER_PORTAL_VIEWER}`),
        value: UserDetailsDtoUserRoleEnum.USER_PORTAL_VIEWER,
      },
    ],
  },
  status: {
    label: t('pages.users.filters.statusFilterTitle'),
    values: [
      {
        label: t('pages.users.statuses.active'),
        value: UserDetailsDtoStatusEnum.ACTIVE,
      },
      {
        label: t('pages.users.statuses.inactive'),
        value: UserDetailsDtoStatusEnum.INACTIVE,
      },
    ],
  },
});

export const fieldsToSend = [
  'search',
  'status',
  'userRole',
  'sort',
  'size',
  'page',
];
