import { Popover, styled } from '@mui/material';

export const UserMenuPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    minWidth: '250px',
    maxWidth: '320px',
    borderRadius: '10px',
    boxShadow: '0 3px 24px 0 rgba(30, 30, 30, 0.4)',
    backgroundColor: theme.palette.white.main,
    overflowWrap: 'break-word',
  },
}));
