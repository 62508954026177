import { styled } from '@mui/material/styles';

import { WindTurbineDtoOperationalStatusEnum } from 'openapi-api/admin-service';

export const OperationalStatusWrapper = styled('div')<{
  operationalStatus?: WindTurbineDtoOperationalStatusEnum;
}>(({ theme, operationalStatus }) => {
  let bgColor;
  let circleColor;

  switch (operationalStatus) {
    case WindTurbineDtoOperationalStatusEnum.DEACTIVATED:
      bgColor = theme.palette.grey['50'];
      circleColor = theme.palette.grey['200'];
      break;
    case WindTurbineDtoOperationalStatusEnum.ACTIVATED:
      bgColor = theme.palette.green['200'];
      circleColor = theme.palette.green['600'];
      break;
    default:
      bgColor = theme.palette.grey['50'];
      circleColor = theme.palette.grey['200'];
  }

  return {
    display: 'flex',
    maxWidth: '100%',
    padding: theme.spacing(0, 1.5, 0, 4),
    borderRadius: theme.spacing(2),
    backgroundColor: bgColor,
    position: 'relative',
    width: 'fit-content',
    '&:before': {
      content: '""',
      width: '12px',
      height: '12px',
      borderRadius: '12px',
      backgroundColor: circleColor,
      position: 'absolute',
      left: '4px',
      top: '4px',
    },
  };
});
