import React, { FC, useMemo } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';

import Main from './styles/Main';
import MainLayoutWrapper from './styles/MainLayoutWrapper';
import { Header, MobileMenu, SupportRequest } from 'components/common/Header';
import routePaths from 'constants/routePaths';
import useGlobalData from 'contexts/globalData';

export const MainLayout: FC = () => {
  const outlet = useOutlet();
  const { pathname } = useLocation();
  const { isMobileMenuOpen, isSendSupportRequestOpened } = useGlobalData();
  const showBgImage = useMemo(
    () => pathname === routePaths.parks.root,
    [pathname],
  );
  const noPaddings = useMemo(
    () =>
      pathname === routePaths.reports || pathname === routePaths.updateProfile,
    [pathname],
  );

  return (
    <MainLayoutWrapper showBgImage={showBgImage}>
      <Header showBgImage={showBgImage} />
      <Main noPaddings={noPaddings}>{outlet}</Main>
      {isMobileMenuOpen && <MobileMenu />}
      {isSendSupportRequestOpened && <SupportRequest />}
    </MainLayoutWrapper>
  );
};
