import { styled } from '@mui/material';

export const PageWrapper = styled('div')(({ theme }) => ({
  maxWidth: '870px',
  height: '100%',
  margin: '0 auto',
  padding: theme.spacing(5, 0),

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(2.5),
  },
}));
