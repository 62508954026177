import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TabsStyled } from '../../styles';
import { TabsComponentVariant } from 'components/common/Tabs/types';
import routePaths from 'constants/routePaths';
import useWindTurbine from 'contexts/WindTurbine';

export const WindTurbinePageTabs: FC = () => {
  const { windTurbine } = useWindTurbine();
  const { t } = useTranslation();

  const parentPath = useMemo(() => {
    if (!windTurbine?.publicId) return;

    return routePaths.parks
      .specific(windTurbine.location!.id!)
      .windTurbine(windTurbine.publicId);
  }, [windTurbine]);

  const tabs = useMemo(
    () => [
      {
        label: t`pages.turbine.tabs.generalInfo.title`,
        value: parentPath?.general || '',
      },
      {
        label: t`pages.turbine.tabs.statuses.title`,
        value: parentPath?.statuses || '',
      },
    ],
    [t, parentPath],
  );

  return (
    <TabsStyled tabs={tabs} componentVariant={TabsComponentVariant.Tabs} />
  );
};
