import { styled } from '@mui/material';

export const ProfilePageHeadButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),

  [theme.breakpoints.down('desktop')]: {
    display: 'none',
  },
}));
