import routePaths from './routePaths';
import { TranslationFunction } from '../types/general';
import { TabType } from 'components/common/Tabs/types';

export const generateTabs = (
  isSuperAdmin: boolean,
  t: TranslationFunction,
): TabType[] => [
  { label: t('tabs.parks'), value: routePaths.parks.root },
  { label: t('tabs.reports'), value: routePaths.reports },
  ...(isSuperAdmin
    ? [{ label: t('tabs.manageUsers'), value: routePaths.users.root }]
    : []),
  { label: t('tabs.solarPanels'), value: '/solar-panels', disabled: true },
  {
    label: t('tabs.more'),
    value: 'more',
    submenu: [
      {
        label: t('notifications.title'),
        link: routePaths.notifications.root,
        pattern: new RegExp(`^${routePaths.notifications.root}`),
      },
    ],
  },
];
