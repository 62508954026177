import { styled } from '@mui/material';

export const UserCredentialsWrapper = styled('div')(({ theme }) => ({
  borderRadius: '12px',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  backgroundColor: theme.palette.green[500],
  width: '100%',
  textAlign: 'left',
}));
