import { styled } from '@mui/material/styles';

import { TabsSection } from 'components/common/TabsSection/TabsSection';

export const TabSectionsWrapper = styled(TabsSection)(({ theme }) => ({
  width: '100%',
  '& .MuiTabs-root': {
    marginBottom: theme.spacing(3),
    '.MuiTabs-flexContainer': {
      padding: 0,
      gap: theme.spacing(5),
    },
  },
  '& .MuiTabs-scroller': {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
}));
