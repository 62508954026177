import { DialogProps, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

import CloseButton from './styles/CloseButton';
import DialogWrapper from './styles/DialogWrapper';
import { Close } from 'components/icons';

export type CommonDialogProps = {
  yPadding?: number;
  width?: number;
  align?: string;
  testId?: string;
  onClose?: () => void;
} & Omit<DialogProps, 'onClose'>;

const CommonDialog: FC<CommonDialogProps> = ({ testId, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <DialogWrapper
      maxWidth={false}
      fullScreen={fullScreen}
      {...props}
      data-testid={testId}
    >
      {props.onClose && (
        <CloseButton
          data-testid="close-popup"
          onClick={() => props.onClose?.()}
        >
          <Close />
        </CloseButton>
      )}
      {props.children}
    </DialogWrapper>
  );
};
export default CommonDialog;
