import { FC, ReactNode } from 'react';
interface ConditionalWrapperProps<T> {
  condition: boolean;
  Wrapper: FC<T>;
  wrapperProps: T;
  children: ReactNode;
}
export const ConditionalWrapper = <T,>({
  condition,
  Wrapper,
  wrapperProps,
  children,
}: ConditionalWrapperProps<T>) => {
  return condition ? (
    <Wrapper {...wrapperProps}>{children}</Wrapper>
  ) : (
    <>{children}</>
  );
};
