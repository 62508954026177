import { IconButton } from '@mui/material';
import { FC } from 'react';

import { CopyIconWrapper } from './styles';
import { Copy, CopySuccess } from 'components/icons';
import { useCopyToClipboard } from 'utils/hooks/useCopyToClipboard';

interface ICopyIconProps {
  text?: string;
}

export const CopyIcon: FC<ICopyIconProps> = ({ text = '' }) => {
  const { copied, copyText } = useCopyToClipboard(text);

  return (
    <CopyIconWrapper>
      {!copied && (
        <IconButton onClick={copyText}>
          <Copy />
        </IconButton>
      )}
      {copied && <CopySuccess />}
    </CopyIconWrapper>
  );
};
