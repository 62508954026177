import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const MainMenuChevronUpL: FC<CommonSvgProps> = (props) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.168 12.1667L10.0013 8L5.83464 12.1667"
      stroke="#21252C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
