import { FC, ReactNode } from 'react';

import useUser from 'contexts/user';
import { UserDetailsDtoUserRoleEnum } from 'openapi-api/admin-service';

interface Props {
  disallowedRoles: UserDetailsDtoUserRoleEnum[];
  children?: ReactNode | undefined;
}

export const RolePermissionWrapper: FC<Props> = ({
  disallowedRoles,
  children,
}) => {
  const { user } = useUser();

  if (!user.userRole) return null;

  if (disallowedRoles.includes(user.userRole)) return null;

  return <>{children}</>;
};
