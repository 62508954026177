import { Box, Grid } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderWrapper, LogoLink } from './styles';
import CommonButton from '../CommonButton';
import { NotificationCountersBlock } from '../Notifications/NotificationsCountersBlock';
import Tabs from '../Tabs';
import { TabsComponentVariant } from '../Tabs/types';
import { UserMenu } from 'components/common/UserMenu';
import { BurgerMenu, Logo } from 'components/icons';
import routePaths from 'constants/routePaths';
import { generateTabs } from 'constants/tabs';
import useGlobalData from 'contexts/globalData';
import useUser from 'contexts/user';

type Props = {
  showBgImage: boolean;
};

export const Header: FC<Props> = ({ showBgImage }) => {
  const { setIsMobileMenuOpen, setIsSendSupportRequestOpened } =
    useGlobalData();
  const { isSuperAdmin } = useUser();
  const { t } = useTranslation();
  const tabs = useMemo(() => {
    return generateTabs(!!isSuperAdmin, t);
  }, [isSuperAdmin, t]);

  return (
    <HeaderWrapper showBgImage={showBgImage}>
      <Grid container gap={6.5} width="auto" alignItems="center">
        <LogoLink to={routePaths.parks.root}>
          <Logo />
        </LogoLink>
        <Tabs
          componentVariant={TabsComponentVariant.Menu}
          sx={{ display: { mobile: 'none', desktop: 'flex' } }}
          tabs={tabs}
        />
      </Grid>
      <Grid display="flex" gap={2} alignItems="center">
        <NotificationCountersBlock />
        <Grid
          sx={{
            display: { mobile: 'none', desktop: 'flex' },
            gap: 2,
            alignItems: 'center',
          }}
        >
          <CommonButton
            variant="contained"
            color="white"
            data-testid="supportRequest"
            size="small"
            onClick={() => setIsSendSupportRequestOpened(true)}
          >
            {t('buttons.sendSupportRequest')}
          </CommonButton>
          <UserMenu
            sx={{ display: { mobile: 'none', desktop: 'block' } }}
            testId="header-user-menu"
          />
        </Grid>
        <Box
          sx={{
            display: { mobile: 'flex', desktop: 'none' },
            cursor: 'pointer',
            alignItems: 'center',
          }}
          onClick={() => setIsMobileMenuOpen(true)}
          data-testid="header-mobile-menu"
        >
          <BurgerMenu />
        </Box>
      </Grid>
    </HeaderWrapper>
  );
};
