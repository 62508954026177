import { SxProps, Tab, Typography } from '@mui/material';
import { FC, ReactElement, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { TabsStyled } from './styles';
import { TabType, TabsComponentVariant } from './types';
import DropdownMenu from '../DropdownMenu';
import { MainMenuChevronDown, MainMenuChevronUpL } from 'components/icons';

interface TabsProps {
  tabs: TabType[];
  componentVariant: TabsComponentVariant;
  className?: string;
  sx?: SxProps;
}

function a11yProps(value: string) {
  return {
    id: `admin-tab-${value}`,
    'aria-controls': `admin-tabpanel-${value}`,
  };
}

const Tabs: FC<TabsProps> = ({
  tabs,
  componentVariant,
  className,
  sx,
}): ReactElement => {
  const { pathname } = useLocation();

  const value = useMemo(() => {
    const activeTab = tabs.find((tab) => {
      if (tab.submenu?.length) {
        return tab.submenu.find(
          (submenuTab) => submenuTab.link && pathname.match(submenuTab.link),
        )?.link;
      }

      return pathname.match(tab.value);
    });

    if (activeTab?.submenu) {
      return tabs.indexOf(activeTab);
    }

    return activeTab?.value;
  }, [tabs, pathname]);

  return (
    <TabsStyled
      value={value}
      aria-label="tabs"
      componentVariant={componentVariant}
      className={className}
      sx={sx}
    >
      {tabs.map((tab) =>
        !!tab.submenu ? (
          <DropdownMenu
            key={tab.value}
            title={<Typography variant="subheading">{tab.label}</Typography>}
            items={tab.submenu}
            withExpandIcon
            customIcons={{
              down: <MainMenuChevronDown />,
              up: <MainMenuChevronUpL />,
            }}
          />
        ) : (
          <Tab
            key={`${tab.label}-${tab.value}`}
            value={tab.value}
            label={tab.label}
            component={Link}
            to={tab.value}
            disabled={tab.disabled}
            disableRipple
            {...a11yProps(tab.value.split('/').pop()!)}
          />
        ),
      )}
    </TabsStyled>
  );
};

export default Tabs;
