import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const Close: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    color="#1E2228"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 7L17 17M7 17L17 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
