import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AddUserPopup, UsersTable } from './components';
import { usersFilterValues, fieldsToSend } from './constants';
import {
  AddUserButton,
  PageContentWrapper,
  PageWrapper,
  TableHeadWrapper,
} from './styles';
import { FilterMenu, Search } from 'components/common/Table/CommonTable';
import { Plus } from 'components/icons';
import { FilterItem } from 'types/general';
import useDataGrid from 'utils/hooks/useDataGrid';
import { usePopup } from 'utils/hooks/usePopup';

export const Users: FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { t } = useTranslation();
  const { searchParams, onSearch, onFilterModelChange } = useDataGrid();

  const [isAddUserPopupOpened, openAddUserPopup, closeAddUserPopup] =
    usePopup();

  const checkedFilterValues: Record<string, FilterItem> = useMemo(
    () => ({
      status: { value: searchParams.get('status') },
      userRole: { value: searchParams.get('userRole') },
    }),
    [searchParams],
  );

  const filterValues = useMemo(() => usersFilterValues(t), [t]);

  return (
    <PageWrapper>
      <Typography variant="h1">{t('pages.users.title')}</Typography>
      <PageContentWrapper>
        <TableHeadWrapper>
          <Search onSearch={onSearch} sx={{ width: '100%' }} />
          <Box display="flex" gap={2} justifyContent="flex-end">
            <FilterMenu
              checkedFilterValues={checkedFilterValues}
              filterValues={filterValues}
              onFilterModelChange={onFilterModelChange}
              light
              sx={{ ml: 'auto' }}
            />
            <AddUserButton
              onClick={openAddUserPopup}
              endIcon={<Plus />}
              data-testid="add-user-button"
            >
              {isDesktop ? t('pages.users.adminUsers.addUserButtonText') : null}
            </AddUserButton>
          </Box>
        </TableHeadWrapper>
        <UsersTable
          fieldsToSend={fieldsToSend}
          checkedFilterValues={checkedFilterValues}
          filterValues={filterValues}
        />
        {isAddUserPopupOpened && (
          <AddUserPopup isOpen onClose={closeAddUserPopup} />
        )}
      </PageContentWrapper>
    </PageWrapper>
  );
};
