import * as yup from 'yup';

import { alphanumericExtended, withoutSpacesAtStartEnd } from 'constants/regex';
import { TranslationFunction } from 'types/general';

export const validationSchema = (t: TranslationFunction) =>
  yup.object({
    assetName: yup
      .string()
      .required(t('form.errors.fieldRequired'))
      .matches(alphanumericExtended, t('form.errors.turbineName'))
      .matches(withoutSpacesAtStartEnd, t('form.errors.spacesAreNotAllowed'))
      .max(48, t('form.errors.maxLength48')),
  });
