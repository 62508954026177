import dayjs from 'dayjs';

import { TranslationFunction } from 'types/general';

export const getRestoreTimerText = (
  t: TranslationFunction,
  statusUpdateDatetime?: string,
): string | undefined => {
  if (statusUpdateDatetime) {
    const permamentDeletionDate = dayjs(statusUpdateDatetime).add(20, 'days');
    const daysCount = permamentDeletionDate.diff(dayjs(), 'days');

    if (daysCount > 1)
      return `${daysCount} ${t('pages.users.restoreTimerDaysLeft')}`;

    return t('pages.users.restoreTimerLessThan24HoursLeft');
  }
};
