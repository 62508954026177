import { Box, useMediaQuery, useTheme } from '@mui/material';
import Typography, { TypographyOwnProps } from '@mui/material/Typography';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  EditGeneralInfoPopup,
  EmailVerificationStatusChip,
} from './components';
import {
  ArrowLeft,
  EmailWrapper,
  GoBackButton,
  InfoBlockWrapper,
  InfoItemWrapper,
  PageWrapper,
  StyledButton,
} from './styles';
import CommonButton from 'components/common/CommonButton';
import {
  InformerBlock,
  InformerBlockVariants,
} from 'components/common/InformerBlock';
import { UpdatePasswordPopup } from 'components/shared/UpdatePasswordPopup';
import useUser from 'contexts/user';
import { usePopup } from 'utils/hooks/usePopup';
import { useResendEmailVerification } from 'utils/hooks/useResendEmailVerification';

export const UpdateProfile = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user, isSuperAdmin, translatedRole } = useUser();
  const navigate = useNavigate();
  const [isEditInfoPopupOpen, openEditInfoPopup, closeEditInfoPopup] =
    usePopup();
  const [
    isUpdatePasswordPopupOpen,
    openUpdatePasswordPopup,
    closeUpdatePasswordPopup,
  ] = usePopup();

  const [
    isResendVerificationLinkAvailable,
    handleResendVerificationLink,
    checkResendVerificationButtonAvailability,
  ] = useResendEmailVerification();

  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const isTablet = useMediaQuery(theme.breakpoints.only('tablet'));

  const fieldNameProps = useMemo<TypographyOwnProps>(
    () => ({
      variant: 'bodyMStrong',
      color: 'black.900',
      flexBasis: isDesktop ? '180px' : 'auto',
    }),
    [isDesktop],
  );

  useEffect(() => {
    checkResendVerificationButtonAvailability();
  }, [checkResendVerificationButtonAvailability, user]);

  return (
    <PageWrapper>
      <Box display="flex" flexDirection="column" gap={isDesktop ? 5 : 3}>
        <GoBackButton
          variant="text"
          size="text"
          startIcon={<ArrowLeft />}
          onClick={() => navigate(-1)}
        >
          {t('back')}
        </GoBackButton>
        <Typography
          variant="h2"
          color="black.900"
          sx={{ wordBreak: 'break-word' }}
        >
          {user.username}
        </Typography>
      </Box>
      <Box
        display="grid"
        gap={isTablet ? 2.5 : 4}
        {...(isTablet && { gridTemplateColumns: '1fr 1fr' })}
      >
        <Box>
          <Typography variant="h4" color="black.900" mb={3}>
            {t('pages.updateProfile.generalInfo')}
          </Typography>
          <InfoBlockWrapper>
            <Box display="flex" flexDirection="column" gap={isDesktop ? 2 : 3}>
              <InfoItemWrapper>
                <Typography {...fieldNameProps}>
                  {t('pages.updateProfile.accountName')}
                </Typography>
                <Typography variant="bodyM" color="black.900">
                  {user.username}
                </Typography>
              </InfoItemWrapper>
              <InfoItemWrapper>
                <Typography {...fieldNameProps}>{t('role')}</Typography>
                <Typography variant="bodyM" color="black.900">
                  {translatedRole}
                </Typography>
              </InfoItemWrapper>
              {!isSuperAdmin && (
                <>
                  <InfoItemWrapper>
                    <Typography {...fieldNameProps}>
                      {t('emailAddress')}
                    </Typography>
                    <EmailWrapper>
                      <Typography variant="bodyM" color="black.900">
                        {user.email}
                      </Typography>
                      <EmailVerificationStatusChip
                        verified={user.emailVerified}
                      />
                    </EmailWrapper>
                  </InfoItemWrapper>
                  {user.email && !user.emailVerified && (
                    <InformerBlock
                      variant={InformerBlockVariants.Warning}
                      title={t('pages.updateProfile.checkYourEmail')}
                      sx={{
                        flexDirection: { desktop: 'row' },
                        justifyContent: { desktop: 'space-between' },
                        alignItems: { desktop: 'center' },
                      }}
                    >
                      <CommonButton
                        size="small"
                        color="warning"
                        sx={{ width: 'fit-content' }}
                        disabled={!isResendVerificationLinkAvailable}
                        onClick={handleResendVerificationLink}
                      >
                        {isResendVerificationLinkAvailable
                          ? t('buttons.resendVerificationLink')
                          : t('pages.updateProfile.verificationLinkIsSent')}
                      </CommonButton>
                    </InformerBlock>
                  )}
                </>
              )}
            </Box>
            {!isSuperAdmin && (
              <StyledButton variant="outlined" onClick={openEditInfoPopup}>
                {t('pages.updateProfile.editInfo')}
              </StyledButton>
            )}
          </InfoBlockWrapper>
        </Box>
        <Box>
          <Typography variant="h4" color="black.900" mb={3}>
            {t('password')}
          </Typography>
          <InfoBlockWrapper>
            <Box display="flex" flexDirection="column" gap={isDesktop ? 2 : 3}>
              <InfoItemWrapper>
                <Typography {...fieldNameProps}>{t('password')}</Typography>
                <Typography variant="bodyM" color="black.900">
                  {'•••••••••••••••'}
                </Typography>
              </InfoItemWrapper>
            </Box>
            <StyledButton variant="outlined" onClick={openUpdatePasswordPopup}>
              {t('pages.updateProfile.updatePassword')}
            </StyledButton>
          </InfoBlockWrapper>
        </Box>
      </Box>
      <EditGeneralInfoPopup
        isOpen={isEditInfoPopupOpen}
        onClose={closeEditInfoPopup}
      />
      {isUpdatePasswordPopupOpen && (
        <UpdatePasswordPopup onClose={closeUpdatePasswordPopup} />
      )}
    </PageWrapper>
  );
};
