import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { CheckMarkIcon } from './styles';
import { RedCross } from 'components/icons';

type Props = {
  value: string;
  regExp: RegExp;
  title: string | null;
};

export const ValidationLine = ({ title, regExp, value }: Props) => {
  const icon = useMemo(() => {
    if (!value) return <CheckMarkIcon />;
    return regExp.test(value) ? <CheckMarkIcon isActive /> : <RedCross />;
  }, [value, regExp]);

  return (
    <Grid
      gap={1.5}
      marginBottom={1}
      display="grid"
      gridTemplateColumns="24px 1fr"
      alignItems="center"
    >
      {icon}
      <Typography variant="bodyXS">{title}</Typography>
    </Grid>
  );
};
