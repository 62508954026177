import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

export const validationSchema = (t: TranslationFunction) =>
  yup.object({
    organizationId: yup.string().required(t('form.errors.fieldRequired')),
    username: yup.string().required(t('form.errors.fieldRequired')),
    password: yup.string().required(t('form.errors.fieldRequired')),
  });
