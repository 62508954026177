import { FC } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CommonButton from '../CommonButton';
import { useCopyToClipboard } from 'utils/hooks/useCopyToClipboard';

interface ICopyButtonProps {
  text?: string;
  light?: boolean;
}

export const CopyButton: FC<ICopyButtonProps> = ({ text = '', light }) => {
  const { copied, copyText } = useCopyToClipboard(text);
  const { t } = useTranslation();

  return (
    <>
      {!copied && (
        <CommonButton
          variant="outlined"
          color={light ? 'white' : undefined}
          onClick={copyText}
          size="medium"
          sx={(theme) => ({
            width: '180px',
            backgroundColor: 'transparent',
            outlineColor: theme.palette.grey[400],
            color: theme.palette.black[900],

            '&:hover': {
              backgroundColor: theme.palette.green[200],
            },
          })}
        >
          {t('copyCreds')}
        </CommonButton>
      )}
      {copied && (
        <CommonButton
          variant="contained"
          size="medium"
          sx={(theme) => ({
            width: '180px',
            my: 0.25,
            backgroundColor: theme.palette.green[200],
            '&:hover': {
              backgroundColor: theme.palette.green[200],
            },
          })}
        >
          {t('copied')}
        </CommonButton>
      )}
    </>
  );
};
