import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SuperAdminForm, UserForm } from './components';
import {
  FormContainer,
  LoginContainer,
  LogoContainer,
  TabSectionsWrapper,
} from './styles';
import { ITab } from 'components/common/TabsSection/TabsSection';
import { Error, Logo } from 'components/icons';

export const Login = () => {
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const tabs: ITab[] = [
    {
      label: (
        <Typography variant="bodyMStrong">{t('pages.logIn.user')}</Typography>
      ),
      value: <UserForm setError={setError} />,
      key: 'login-tab-user',
    },
    {
      label: (
        <Typography variant="bodyMStrong">
          {t('pages.logIn.superAdmin')}
        </Typography>
      ),
      value: <SuperAdminForm setError={setError} />,
      key: 'login-tab-super-admin',
    },
  ];

  return (
    <LoginContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Grid container alignItems="center" justifyContent="center">
        <FormContainer>
          <Typography variant="h2" sx={{ mb: 5 }}>
            {t('pages.logIn.title')}
          </Typography>
          {error && (
            <Alert
              iconMapping={{
                error: <Error />,
              }}
              severity="error"
              sx={{ mb: 5 }}
            >
              {t('pages.logIn.incorrect')}
            </Alert>
          )}
          <TabSectionsWrapper tabs={tabs} variant="fullWidth" />
        </FormContainer>
      </Grid>
    </LoginContainer>
  );
};
